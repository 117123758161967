import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import { PageHeaderV2, RibbonCTA, SEO } from "src/components";

import Main from "./_components/_main";

function MediaPage({ setInvert, setInvertLogo, preview }) {
  return (
    <StaticQuery
      query={graphql`
        {
          getInvolved: prismicGetInvolved {
            dataString
          }
        }
      `}
      render={data => {
        let page = JSON.parse(data.getInvolved.dataString);
        if (preview) {
          page = preview;
        }
        return (
          <>
            <SEO
              image={
                page.opengraph_image &&
                page.opengraph_image.url &&
                page.opengraph_image.url
              }
              imageAlt={
                page.opengraph_image &&
                page.opengraph_image.alt &&
                page.opengraph_image.alt
              }
              title={"Media"}
              description={
                page.media_description &&
                RichText.asText(page.media_description)
              }
            />
            {/* <ThemeProvider
        theme={Theme({
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF"
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color
        })}
      > */}
            <PageHeaderV2
              heading={page.media_header && RichText.asText(page.media_header)}
              image={page.intro_image}
              links={["Apply", "Press Kit"]}
              color="brand.altPrimary"
              invert={true}
              setInvertLogo={setInvertLogo}
              setInvert={setInvert}
            />
            {/* </ThemeProvider>
      <ThemeProvider
        theme={Theme({
          text:
            festival.theme === "Dark theme"
              ? {
                  default: "#fff",
                  body: rgba("#fff", 0.75),
                  alt: rgba("#fff", 0.5),
                  reverse: "#1B1A19",
                  reverseBody: rgba("#1B1A19", 0.75),
                  reverseAlt: rgba("#1B1A19", 0.5)
                }
              : undefined,
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF"
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color
        })}
      > */}
            <>
              {/* {((activeFestival.ongoing_festival &&
          activeFestival.ongoing_festival.uid !== data.festival.uid) ||
          activeFestival.festival_online === "No") && (
          <NotAvailable
            offlineMessage={activeFestival.festival_offline_message}
            offlineButtonText={activeFestival.festival_offline_button_text}
            offlineButtonLink={activeFestival.festival_offline_button_link}
          />
        )}
        {((activeFestival.festival_online === "Yes" &&
          activeFestival.ongoing_festival &&
          activeFestival.ongoing_festival.uid === data.festival.uid) ||
          preview) && ( */}
              <>
                <Main
                  description={
                    page.media_description &&
                    RichText.render(
                      page.media_description,
                      linkResolver,
                      serializer
                    )
                  }
                  buttonText={page.media_button_text}
                  buttonLink={page.media_button_link}
                />
                <RibbonCTA
                  id="press-kit"
                  heading={
                    page.press_kit_header &&
                    RichText.asText(page.press_kit_header)
                  }
                  buttonText={page.press_kit_button_text}
                  buttonLink={page.press_kit_button_link}
                  color="bg.alt"
                />
              </>
              {/* )} */}
            </>
            {/* </ThemeProvider> */}
          </>
        );
      }}
    />
  );
}

export default MediaPage;
